.title-container {
    margin: 106px 80px 20px 80px;
}

.title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.title-text-illustration {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.title-content p {
    margin: 10px 0 0 0;
}