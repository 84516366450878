header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
}

nav {
    margin: 0 80px;
}

@media (max-width: 1020px) {  
    nav {
        margin: 0 40px;
    }
}
@media (max-width: 520px) {  
    nav {
        margin: 0 10px;
    }
}

div.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 0 0;
}

.logo {
    width: 51px;
    height: 40px;
}

div.lien-redirection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
}

div.lien-redirection ul {
    font-family: 'Inter';
    color: black;
    font-size: 18px;
    font-weight: 700;
    height: 22px;
}

div.lien-redirection ul:hover {
    color: grey;
}

hr {
    border: 1px solid black;
    margin: 10px 0 0 0;
}

a {
    text-decoration: none;
    margin: 0;
    color: black;
}

a:hover {
    color: grey;
}

#qui-sommes-nous::before {
    content: "";
    display: block;
    height: 164.5px;
    margin: -164.5px 0 0 0;
}
@media (max-width: 1020px) {
    #qui-sommes-nous::before {
        height: 84.5px;
        margin: -84.5px 0 0 0;
    }
}

.burger-menu {
    display: none;
    cursor: pointer;
}

.burger-line {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
}

.mobile-menu {
    display: none;
}

@media (max-width: 1020px) {
    div.lien-redirection {
        display: none;
    }

    .burger-menu {
        display: flex;
        align-items: center;
    }

    .mobile-menu {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        padding: 10px 80px 0 80px;
    }

    @media (max-width: 1020px) {  
        .mobile-menu {
            padding: 20px 40px 0 40px;
        }
    }
    @media (max-width: 520px) {  
        .mobile-menu {
            padding: 20px 10px 0 10px;
        }
    }

    .mobile-menu ul {
        font-family: 'Inter';
        color: black;
        font-size: 18px;
        font-weight: 700;
        height: 22px;
    }

    .mobile-menu ul:hover {
        color: grey;
    }

    .mobile-menu.active {
        display: block;
    }

    .mobile-menu ul {
        margin-bottom: 15px;
    }

    .mobile-menu .last-item {
        margin-bottom: 0;
    }
}