.title-container {
    margin: 106px 80px 24px 80px;
}

.title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0 0 32px 0  ;
}

.title-text-illustration {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.title-content p {
    margin: 10px 0 0 0;
}

.categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.categories ul {
    font-family: 'Inter';
    color: black;
    font-size: 18px;
    font-weight: 700;
    height: 22px;
}

.categories ul:hover {
    color : grey;
    cursor: pointer;
}

.ideas-list-container {
    margin: 0 80px 104px 80px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.idea-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.user-info {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px 0;
    align-items: flex-start;
}

.idea-title {
    margin: 0 0 0 10px;
}

.idea-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}
.idea-category:hover {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(50%);
}

.idea-content {
    padding: 40px 40px 20px 40px;
    border: 2px solid #000000;
    border-radius: 50px;
    box-sizing: border-box;
}
.idea-content:hover {
    cursor: pointer;
    background-color: rgb(241, 241, 241);
}

.idea-interaction {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
}

.idea-vote {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.idea-vote:hover {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(50%);
}

.idea-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.idea-comment:hover {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(50%);
}

.appel-action-text {
    padding: 40px 40px 20px 40px;
    border: 2px solid #291919;
    border-radius: 50px 50px 0 0;
    box-sizing: border-box;
}

.appel-action-cta {
    display: flex;
    justify-content: center;
    padding: 10px 40px 10px 40px;
    border-left: 2px solid #000000;
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
    border-radius: 0 0 50px 50px;
    box-sizing: border-box;
}
.appel-action-cta:hover {
    cursor: pointer;
    color: white;
    background-color: black;
}

/*CSS de modal*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 40px;
    border: 2px solid #291919;
    border-radius: 50px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
}

.idea-interaction__modal {
    display: flex;
    flex-direction: row;
    gap: 40px;
    height: 100%;
    margin: 20px 0 20px 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}
.close-button:hover {
    filter: brightness(0) saturate(100%) invert(50%);
}

.input-container {
    display: flex;
    align-items: center;
    border: 2px solid black;
    border-radius: 20px;
    padding: 0 0 0 20px;
    margin: 0 0 20px 0;
    background-color: white;
}

.input-field {
    flex-grow: 1;
    height: 40px;
    border: none;
    outline: none;
    font-family: Inter;
    font-size: 16px;
    color: black;
    padding: 0 10px 0 0;
}

.send-button {
    cursor: pointer;
    margin: 0 20px 0 10px;
}
.send-button:hover {
    filter: brightness(0) saturate(100%) invert(50%);
}

.list-comments-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comment {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.img-comments {
    width: 60px;
    height: 60px;
}