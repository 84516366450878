.cta-join {
    display: inline-block;
    overflow: hidden;
    width: 310px;
    background-color: white;
    padding: 20px 40px;
    border: 2px solid #000000;
    border-radius: 50px;
    text-align: center;
    box-sizing: border-box;
}
@media (max-width: 650px) {  
	.cta-join {
        width: 226px;
        padding: 20px 40px;
    }
}

.cta-join:hover {
    background-color: black;
    color: white;
    border-color: white;
}

.cta-join p {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: black;
    text-decoration: none;
}
@media (max-width: 650px) {  
	.cta-join p {
        font-size: 18px;
    }
}

.cta-join:hover p {
    color: white;
}